<template>
  <b-button
    tag="a"
    block
    variant="outline-secondary"
    :disabled="!ncalayerConnected"
    size="sm"
    @click="onClick"
  >
    <template v-if="!ncalayerConnected && !ncalayerConnectionError">
      <b-spinner small />
      <slot name="connecting-state">
        Подключение к NCALayer
      </slot>
    </template>
    <slot v-else name="connected-state" />
  </b-button>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'NcalayerButton',
  computed: {
    ncalayerConnected: get('ncalayer/isConnected'),
    ncalayerConnectionError: get('ncalayer/connectionError')
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped>

</style>
