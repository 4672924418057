<template>
  <div class="login-container" :style="{'background-image': 'url(' + require('~/assets/bg/login-bg.png') + ')'}">
    <div class="login-form_container p-l-55 p-r-55 p-t-65 p-b-50 text-center">
      <h2>
        Кабинет ЦТО
      </h2>

      <b-tabs align="center" small>
        <b-tab title="ЭЦП">
          <div class="login-form_e-signature">
            <div class="login-form_item">
              <ncalayer-button @click="_authenticateByDigitalSignature">
                <template slot="connected-state">
                  Выбрать ЭЦП
                </template>
              </ncalayer-button>
              <span v-if="ncalayerConnectionError" class="ws-error">NCAlayer не включен, включите чтобы продолжить</span>
            </div>
            <div class="login-form_item">
              <b-button variant="primary">
                Войти
              </b-button>
            </div>
          </div>
        </b-tab>

        <b-tab title="Логин">
          <div class="login-form_enter">
            <div class="login-form_item">
              <b-form-input v-model="credentials.email" type="email" placeholder="Логин" />
            </div>
            <div class="login-form_item">
              <b-form-input v-model="credentials.password" type="password" text="password" placeholder="Пароль" />
              <b-form-invalid-feedback :state="!errorMessage">
                {{ errorMessage }}
              </b-form-invalid-feedback>
            </div>
            <div class="login-form_item">
              <b-button variant="primary" @click="doLogin">
                Войти
              </b-button>
            </div>
          </div>
        </b-tab>
      </b-tabs>

      <div class="login-form_footer">
        <div class="login-form_footer-item">
          <nuxt-link to="/signup" class="footer-link small">
            Еще не зарегистрированы?
          </nuxt-link>
        </div>
        <div class="login-form_footer-item">
          <nuxt-link to="/resetPassword" class="footer-link small">
            Восстановить доступ
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { call, get } from 'vuex-pathify'
import NcalayerButton from '~/components/NcalayerButton'

export default {
  components: { NcalayerButton },
  data () {
    return {
      errorMessage: null,
      credentials: {
        email: null,
        password: null
      }
    }
  },
  computed: {
    ncalayerConnected: get('ncalayer/isConnected'),
    ncalayerConnectionError: get('ncalayer/connectionError'),
    connectedWS () {
      return this.$store.state.socket.isConnected
    }
  },
  mounted () {
    this.ncalayerConnect()
  },
  beforeDestroy () {
    this.ncalayerDisconnect()
  },
  methods: {
    async _authenticateByDigitalSignature () {
      const { token } = await this.$axios.$get('/api/cabinet/security/token')
      const signature = await this.ncalayerSendMessage({ module: 'kz.gov.pki.knca.commonUtils', method: 'createCAdESFromBase64', args: ['PKCS12', 'AUTH', token, true] })
      try {
        await this.authenticateByDigitalSignature(signature)
        window.location.href = '/cabinet'
      } catch (e) {
        alert(e.response.data.message)
      }
    },
    ncalayerSendMessage: call('ncalayer/sendMessage'),
    closeErrorMessage: call('signup/clearOperationError'),
    ncalayerConnect: call('ncalayer/connect'),
    ncalayerDisconnect: call('ncalayer/disconnect'),
    login: call('ctoUserAuthentication/login'),
    authenticateByDigitalSignature: call('ctoUserAuthentication/authenticateByDigitalSignature'),
    async doLogin () {
      this.errorMessage = null
      try {
        await this.login(this.credentials)
        window.location.href = '/cabinet'
      } catch (e) {
        if (e.response.status === 401) {
          this.errorMessage = e.response.data.message
        }
      }
    }
  }
}
</script>

<style lang="scss">

  .login {
    &-container {
       width: 100%;
       min-height: 100vh;
       display: -webkit-box;
       display: -webkit-flex;
       display: -moz-box;
       display: -ms-flexbox;
       display: flex;
       flex-wrap: wrap;
       justify-content: center;
       align-items: flex-start;
       padding: 190px 20px 0;
       background-color: #F8F9FC;
       background-size: 100% auto;
       background-position: center top 110px;
       background-repeat: no-repeat;
    }
    &-form {
      &_container {
        width: 640px;
        background: #fff;
        overflow: hidden;
        padding: 50px 0;
        -webkit-border-radius:3px;
        -moz-border-radius:3px;
        border-radius:3px;
        > h2 {
            font-weight: 500;
            font-size: 36px;
            line-height: 33px;
            margin: 0 0 12px;
          }
        > p {
            font-size: 13px;
            line-height: 20px;
            padding: 0 40px;
            margin: 0 0 26px;
          }
        .nav-tabs {
          border-bottom: 2px solid #CAD0DF;
          .nav-link {
            border: none;
            width: 130px;
            font-size: 11px;
            text-transform: uppercase;
            line-height: 20px;
            padding: 0 0 6px;
            color: #1B2E4B;
            &.active {
              color: #0168FA;
              border-bottom: 2px solid #0168FA;
            }
          }
          .nav-item {
            margin-bottom: -2px;
          }
        }
        .btn {
          font-size: 13px;
          width: 260px;
          height: 36px;
          margin: 0 auto;
        }

        .form-control {
          text-align: center;
          font-size: 13px;
          height: 36px;
          width: 260px;
          margin: 0 auto;
        }
      }
      &_enter {
        padding: 45px 20px 0;
      }
      &_e-signature {
        padding: 45px 20px 30px;
        button {
          min-width: 260px;
        }
      }
      &_footer {
        padding: 33px 0 0;
        &-item {
          margin: 10px 0 0;
          font-size: 0;
          &:first-child {
            margin: 0;
          }
        }
        a {
          color: #8392A5;
          display: inline-block;
          line-height: 20px;
          font-size: 12px;
        }
      }
      &_item {
        margin: 14px 0 0;
        text-align: center;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
  .ws-error {
    color: #FF0000;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
</style>
